import { cloneObject } from "@/util/cloneUtils";
import axios from "axios";

export enum RegisterNumberListStatisticsIssueField {
    LAST_SUCCESSFUL = "LAST_SUCCESSFUL",
    LAST_SUCCESSFUL_SIZE = "LAST_SUCCESSFUL_SIZE",
    LAST_SUCCESSFUL_DISPLAY_COUNT = "LAST_SUCCESSFUL_DISPLAY_COUNT",
}

export interface RegisterNumberListStatisticsWithIssues {
    readonly registerNumber: number;
    readonly totalTryCount: number;
    readonly lastTry: Date;
    readonly lastSuccessful: Date | null;
    readonly lastSuccessfulSize: number | null;
    readonly lastSuccessfulDisplayCount: number | null;
    readonly issueFields: RegisterNumberListStatisticsIssueField[];
}

export interface RegisterNumberListStatisticsWithIssuesResult {
    readonly totalSize: number;
    readonly result: RegisterNumberListStatisticsWithIssues[];
}

interface RegisterNumberListStatisticsApi {
    getAllWithIssues(): Promise<RegisterNumberListStatisticsWithIssuesResult>;
}

export const registerNumberListStatisticsApi: RegisterNumberListStatisticsApi = {
    async getAllWithIssues() {
        return cloneObject((await axios.get("/api/register-number-list-statistics")).data);
    },
};
