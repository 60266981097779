
import { corporationApi } from "@/api/corporation";
import {
    registerNumberListStatisticsApi,
    RegisterNumberListStatisticsIssueField,
    RegisterNumberListStatisticsWithIssues,
} from "@/api/registernumberliststatistics";
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            items: [] as RegisterNumberListStatisticsWithIssues[],
            totalSize: 0,
            loading: true,
        };
    },

    computed: {
        RegisterNumberListStatisticsIssueField() {
            return RegisterNumberListStatisticsIssueField;
        },
    },

    methods: {
        hasIssue(item: RegisterNumberListStatisticsWithIssues, issue: RegisterNumberListStatisticsIssueField) {
            return item.issueFields.indexOf(issue) > -1;
        },

        async reload() {
            try {
                await this.loadItems();
            } catch (e) {
                this.$nextTick(() => {
                    throw e;
                });
            }
        },

        async loadItems() {
            this.items = [];
            this.loading = true;
            try {
                const result = await registerNumberListStatisticsApi.getAllWithIssues();
                this.totalSize = result.totalSize;
                this.items = result.result;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                throw e;
            }
        },

        async processAndStoreRegisterNumber(registerNumber: number) {
            alert(JSON.stringify(await corporationApi.processAndStoreRegisterNumber(registerNumber), null, 2));
        },
    },

    async mounted() {
        await this.loadItems();
    },
});
